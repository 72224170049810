import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyCYetWBKfSdhY85kRBw11dnbFfNKmUuOkE",
  authDomain: "dansupport-e7c52.firebaseapp.com",
  projectId: "dansupport-e7c52",
  storageBucket: "dansupport-e7c52.appspot.com",
  messagingSenderId: "257905124280",
  appId: "1:257905124280:web:16e7cbf4206b0e3295654d",
  measurementId: "G-FGCY30CFGC"
};


// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export default app;
